<template>
    <base-modal
        centered
        :title="journey?.name"
        :size="$device.isMobile ? 'fill' : 'xl'"
        :no-footer="!journey?.nextContent"
        :busy="busy"
        scrollable
    >
        <journey-view
            :value="journey"
            hide-title
            :content-clickable="contentClickable"
            :swimlane-props="{
                perView: {
                    md: 2.4,
                    lg: 3.4,
                    xl: 4.4,
                }
            }"
        />

        <template #footer>
            <journey-next-content-bar
                v-if="journey?.nextContent"
                :value="journey?.nextContent"
                class="w-100"
            />
        </template>
    </base-modal>
</template>

<script lang="ts">
    import { PropType, defineComponent } from 'vue'
    import BaseModal from '@common/Modals/BaseModal.vue'
    import JourneyView from './JourneyView.vue'
    import JourneyNextContentBar from './JourneyNextContentBar.vue'


    export default defineComponent({
        components: {
            BaseModal,
            JourneyView,
            JourneyNextContentBar,
        },

        props: {
            value: {
                type: Number as PropType<number>,
                required: true,
            },

            contentClickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
        },

        data() {
            return {
                busy: false,
                journey: null as null | JourneyModel,
            }
        },

        watch: {
            /**
             * Dismiss modal if route changes
             */
            '$route'(value, oldValue): void {
                if (value.name !== oldValue.name)
                    (this as any).$close()
            },
        },

        async created(): Promise<void> {
            this.setQueryParams()

            try {
                this.busy = true
                await this.$store.load('Journeys')
                this.journey = await this.$store.dispatch('Journeys/get', this.value)
            } finally {
                this.busy = false
            }
        },

        beforeDestroy(): void {
            this.removeQueryParams()
        },

        methods: {
            setQueryParams(): void {
                const journeyId = this.$route.query['journey']
                if (!journeyId || Number(journeyId) !== this.value)
                    this.$router.replace({ query: { journey: String(this.value) } })
            },

            removeQueryParams(): void {
                this.$router.replace({ query: {
                    ...this.$route.query,
                    journey: undefined,
                } })
            },
        },
    })
</script>
